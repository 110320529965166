import {postBasic, getBasicRaw, postFile} from "./api";
import {notification} from "antd";
import {cast2number, objectId, stripSkip, toInt} from "./util";
export async function dingLogin(code){
    const pl = {
        code,
        appId: 4
    } as any;
    if (process.env.REACT_APP_USER_ID) {
        pl.jobnumber = process.env.REACT_APP_USER_ID
    }
    return await getBasicRaw('/clearingapi/v1/auth/ding/login',pl)
}
export async function sendSms(phone:string){
    const pl = {
        phone
    }
    return await getBasicRaw('/clearingapi/v1/auth/phone/code/sms',pl)
}
export async function smsLogin(code:string){
    const pl = {
        code,
        appId:4
    }
    return await getBasicRaw('/clearingapi/v1/auth/device/code/login',pl)
}
export async function getCashReturnList(page,pageSize){
    const pl = {
        page,
        pageSize
    }
    const res:PagedVO<CashReturn> = await getBasicRaw('/clearingapi/v1/cashreturn/list',pl)
    return res
}

export async function batchAddCashReturn(pl:CashReturn[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/cashreturn/batch/add',{},pl)
    return res
}

export async function getAdminMemberList(page,pageSize){
    const pl = {
        page,
        pageSize
    }
    const res:PagedVO<AdminMember> = await getBasicRaw('/clearingapi/v1/adminmember/list',pl)
    return res
}
export async function deleteAdminMember(record:AdminMember){
    const pl = {
        id:record.eamId
    }
    const res:MessageVO = await getBasicRaw('/clearingapi/v1/adminmember/delete',pl)
    return res
}
export async function batchAddAdminMember(pl:AdminMember[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/adminmember/batch/add',{},pl)
    return res
}
export async function addAdminMember(pl:AdminMember){
    const res:MessageVO = await postBasic('/clearingapi/v1/adminmember/add',{},pl)
    return res
}
export async function updateAdminMember(pl:AdminMember){
    const res:MessageVO = await postBasic('/clearingapi/v1/adminmember/update',{},pl)
    return res
}

export async function addMapRelation(pl:MapRelation){
    const res:MessageVO = await postBasic('/clearingapi/v1/staffProvinceChannel/addOrModify',{},pl)
    return res
}
export async function updateRecordArea(pl:AdminMember){
    const res:MessageVO = await postBasic('/clearingapi/v1/channel/admin/checkin/county/modify',{},pl)
    return res
}

export async function getNameByEmpNo(empNoList:string[]){
    const res:SimpleObjVO<string[]> = await postBasic('/clearingapi/v1/person/name/by/no',{},empNoList)
    return res
}
export async function getOrderList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<Order> = await postBasic('/clearingapi/v1/order/list',param,condition)
    return res
}
export async function getOrderSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:SimpleListVO<Order> = await postBasic('/clearingapi/v1/order/slice',param,condition)
    return res
}
export async function getUserPageList(){
    const res:SimpleListVO<string> = await getBasicRaw('/clearingapi/v1/rbac/user/page/list', {})
    return res
}
export async function getUserRoleList(){
    const res:SimpleListVO<string> = await getBasicRaw('/clearingapi/v1/rbac/user/role/list', {})
    return res
}
export async function getActionLogList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<ActionLog> = await postBasic('/clearingapi/v1/actionlog/list',param,condition)
    return res
}
export async function getOrderCount(condition){
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/order/count',{},condition)
    return res
}
export async function updateOrder(pl:Order){
    const res:MessageVO = await postBasic('/clearingapi/v1/order/update',{},pl)
    return res
}
export async function getParticipantCount(){
    const res:SimpleObjVO<number> = await getBasicRaw('/qh5api/v1/participant/count')
    return res
}
export async function getTypeCount() {
    const res:SimpleListVO<{type:string,count:number}> = await getBasicRaw('/qh5api/v1/type/count')
    return res
}

export async function getCheckinRecordList(page,pageSize,condition){//签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/list',param,c_data)
    return res
}
export async function getCheckinRecordglList(page,pageSize,condition){//签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/list',param,c_data)
    return res
}

export async function getDetailTabList(page,pageSize,condition){//
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/daily/details',param,c_data)
    return res
}
export async function getglDetailTabList(page,pageSize,condition){//
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/daily/details',param,c_data)
    return res
}
export async function getglStatisList(page,pageSize,condition){//
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/total/statistics',param,c_data)
    return res
}

export async function getStatisList(page,pageSize,condition){//
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/total/statistics',param,c_data)
    return res
}

export async function getCheckinChannelList(page,pageSize,condition){//商务签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<CheckInChannelV2> = await postBasic('/clearingapi/v1/channel/admin/checkin/list',param,c_data)
    return res
}
export async function getChanneWeeklList(page,pageSize,condition){//商务签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)

    const res:PagedVO<ChannelWeekV> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/week',param,c_data)
    return res
}
export async function getChanneMonthlList(page,pageSize,condition){//商务签到结果列表接口
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<ChannelWeekV> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/month',param,c_data)
    return res
}


export async function getRecordStoreList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<RecordStore> = await postBasic('/clearingapi/v1/recordstore/list',param,c_data)
    return res
}
export async function getRecordCompany(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<RecordStore> = await postBasic('/clearingapi/v1/recordstore/channel/list',param,c_data)
    return res
}
export async function getRecordAreaList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const newcod={
        oldProvince:condition.province,
        oldCity:condition.city,
        oldCounty:condition.county
    }
    const c_data=stripSkip(newcod)
    const res:PagedVO<RecordArea> = await postBasic('/clearingapi/v1/recordstore/channel/county/list',param,c_data)
    return res
}

export async function getChannelRecordStoreList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const c_data=stripSkip(condition)
    const res:PagedVO<RecordLevel> = await postBasic('/clearingapi/v1/agreementChannel/list',param,c_data)
    return res
}
export async function getChannelMapRelationList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    // const c_data=stripSkip(condition)
    const res:any = await postBasic('/clearingapi/v1/staffProvinceChannel/list',param,{})
    return res
}

export async function getStoreList(page,pageSize){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<CheckinStore> = await getBasicRaw('/clearingapi/v1/checkin/store/list',param)
    return res
}
export async function addCheckinStore(row:CheckinStore){
    const res:MessageVO = await postBasic('/clearingapi/v1/checkin/store/add',{},row)
    return res
}
export async function getCheckinCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/admin/checkin/count',{},condition)
    return res
}
export async function getCheckinglCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/count',{},condition)
    return res
}

export async function getglStatisCount(condition){//
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/total/statistics/count',{},condition)
    return res
}

export async function getStatisCount(condition){//
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/admin/checkin/total/statistics/count',{},condition)
    return res
}

export async function getDetailTabCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/admin/checkin/daily/details/count',{},condition)
    return res
}
export async function getglDetailTabCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/daily/details/count',{},condition)
    return res
}
export async function getChannelCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/channel/admin/checkin/count',{},condition)
    return res
}
export async function getChannelWeekCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/week/count',{},condition)
    return res
}
export async function getChannelMonthCount(condition){
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleObjVO<number> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/month/count',{},condition)
    return res
}

export async function getglStatisSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/total/statistics/slice',param,condition)
    return res
}
export async function getStatisSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/total/statistics/slice',param,condition)
    return res
}
export async function getDetailTabSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/daily/details/slice',param,condition)
    return res
}
export async function getglDetailTabSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/daily/details/slice',param,condition)
    return res
}
export async function getCheckinSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/admin/checkin/slice',param,condition)
    return res
}
export async function getCheckinglSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInRecordV2> = await postBasic('/clearingapi/v1/phototherapy/admin/checkin/slice',param,condition)
    return res
}
export async function getChannelSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInChannelV2> = await postBasic('/clearingapi/v1/channel/admin/checkin/slice',param,condition)
    return res
}
export async function getChannelWeekSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInChannelV2> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/week/slice',param,condition)
    return res
}
export async function getChannelMonthSlice(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    if(condition.daqu){
        condition.region=condition.daqu
        delete condition.daqu
    }
    if(condition.shengqu){
        condition.province=condition.shengqu
        delete condition.shengqu
    }
    const res:SimpleListVO<CheckInChannelV2> = await postBasic('/clearingapi/v1/channel/admin/checkin/stat/month/slice',param,condition)
    return res
}

// get JfsdCategory list
export async function getJfsdCategoryList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<JfsdCategory> = await postBasic('/i18napi/v1/jfsd-category/list',param,condition)
    return res
}
// getJfsdBannerDataListForLogo
export async function getJfsdBannerDataListForLogo(page,pageSize,condition){
    return await getJfsdBannerDataList(page,pageSize,condition,1)
}
// getJfsdBannerDataListFor3
export async function getJfsdBannerDataListFor3(page,pageSize,condition){
    return await getJfsdBannerDataList(page,pageSize,condition,3)
}
// getJfsdBannerDataListFor4
export async function getJfsdBannerDataListFor4(page,pageSize,condition){
    return await getJfsdBannerDataList(page,pageSize,condition,4)
}
// get JfsdBannerData list
export async function getJfsdBannerDataList(page,pageSize,condition,bid=2){
    const param = {
        page,
        pageSize
    }
    const preparedCondition = {
        ...condition,
        bid
    }
    const res:PagedVO<JfsdBannerData> = await postBasic('/i18napi/v1/jfsd-banner-data/list',param,preparedCondition)
    return res
}
// get JfsdNews list for type = News
export async function getJfsdNewsListForNews(page,pageSize,condition){

    const mergedCondition = {
        ...condition,
        type:'News'
    }
    return await getJfsdNewsList(page,pageSize,mergedCondition)
}
// get JfsdNews list for type = News3
export async function getJfsdNewsListForNews3(page,pageSize,condition){

        const mergedCondition = {
            ...condition,
            type:'News3'
        }
        return await getJfsdNewsList(page,pageSize,mergedCondition)
}
export async function getJfsdNewsList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<JfsdNews> = await postBasic('/i18napi/v1/jfsd-news/list',param,condition)
    let { list , ...rest } = res
    list = list.map(item=>{
        return Object.assign(item,JSON.parse(item.extends))
    })
    return {
        ...rest,
        list,
    }
}
export function decodeHtmlEntities(str) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
}
// get one jfsdpage by id
export async function getJfsdPageById(id){
    const res:SimpleObjVO<JfsdPage> = await postBasic(`/i18napi/v1/jfsd-pages/get`,{id},{})
    // decodeHtmlEntities content and content2 if exists and not empty
    let { obj } = res
    if(obj.content){
        obj.content = decodeHtmlEntities(obj.content)
    }
    if(obj.content2){
        obj.content2 = decodeHtmlEntities(obj.content2)
    }
    return obj
}
export async function getJfsdPageList(page,pageSize,condition) {
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<JfsdPage&{catname:string,catdepth:number}> = await postBasic('/i18napi/v1/jfsd-pages/list',param,condition)
    // decodeHtmlEntities content and content2 if exists and not empty
    let { list , ...rest } = res
    list = list.map(item=>{
        // if catname and catdepth exist, convert then to catname =  catdepth * ' ' + catname
        if(item.catname && item.catdepth){
            item.catname = `${' '.repeat(item.catdepth)}${item.catname}`
        }
        return item
    })
    return {
        ...rest,
        list,
    }
}
// get RnmDeviceModel list
export async function getRnmDeviceModelList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDeviceModel> = await postBasic('/gusadminapi/v1/model/list',param,condition)
    return res
}
// get RnmDeviceMode list
export async function getRnmDeviceModeList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDeviceMode> = await postBasic('/gusadminapi/v1/devicemode/list',param,condition)
    return res
}

// get RnmDevicePosters list
export async function getRnmDevicePostersList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDevicePosters> = await postBasic('/gusadminapi/v1/deviceposters/list',param,condition)
    return res
}
// get RnmDeviceMode list for non-reserved
export async function getRnmDeviceModeListForNonReserved(page,pageSize,condition){
    // add is_reserved = 1 to condition
    if(!condition) condition = {}
    condition.is_reserved = 1
    return await getRnmDeviceModeList(page,pageSize,condition)
}

// get RnmDeviceMode list for non-reserved from local
export async function getRnmDeviceModeListForNonReservedFromLocal(page,pageSize,condition):Promise<PagedVO<RnmDeviceMode>>{
    const list = await getRnmDeviceModeFromLocal()
    // log method and list
    console.log('getRnmDeviceModeListForNonReservedFromLocal',list)
    // filter is_reserved = 1
    const filteredList = list.filter(item=>item.is_reserved == 1)
    return {
        list:filteredList,
        total:filteredList.length,
        currentPage:page,
        status:0,
    }
}
// get RnmDevicePosters list from local
export async function getRnmDevicePostersFromLocalPageVO(page,pageSize,condition){
    const list = await getRnmDevicePostersFromLocal()
    // log method and list
    console.log('getRnmDevicePostersListFromLocal',list)
    return {
        list,
        total:list.length,
        currentPage:page,
        status:0,
    }
}

// get RnmDevicePosters list from local
export async function getRnmDevicePostersFromLocal(){
    // get from window object for now
    const jsonPl = window['rnmDevicePostersLocal']
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    const res =  JSON.parse(jsonPl)
    return res
}

// get RnmDeviceMode list for reserved
export async function getRnmDeviceModeListForReserved(page,pageSize,condition){
    // add is_reserved = 2 to condition
    if(!condition) condition = {}
    condition.is_reserved = 2
    return await getRnmDeviceModeList(page,pageSize,condition)
}
// get RnmDevicePayloadTemplate list
export async function getRnmDevicePayloadTemplateList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDevicePayloadTemplate> = await postBasic('/gusadminapi/v1/payloadtemplate/list',param,condition)
    return res
}
// get RnmDevicePayloadTemplate list from local
export async function getRnmDevicePayloadTemplateFromLocalPageVO(page,pageSize,condition:{rdmd_id:number}){
    const {rdmd_id } = condition
    const list = await getRnmDevicePayloadTemplateFromLocal(rdmd_id)
    // log method and list
    console.log('getRnmDevicePayloadTemplateListFromLocal',rdmd_id,list)
    return {
        list,
        total:list.length,
        currentPage:page,
        status:0,
    }
}
// persist step i done to local
export function persistRnmDeviceAddStepIIsDoneToLocalStorage(step:number){
    if (!window['rnmDeviceAddStepIIsDone']) window['rnmDeviceAddStepIIsDone'] = {}
    window['rnmDeviceAddStepIIsDone'][step] = true
}
// is step i done
export function isRnmDeviceAddStepIIsDone(step:number){
    if (!window['rnmDeviceAddStepIIsDone']) window['rnmDeviceAddStepIIsDone'] = {}
    return window['rnmDeviceAddStepIIsDone'][step]
}
// get all done steps
export function getAllRnmDeviceAddStepIIsDone():number[]{
    if (!window['rnmDeviceAddStepIIsDone']) window['rnmDeviceAddStepIIsDone'] = {}
    const res:number[] = []
    for(let i in window['rnmDeviceAddStepIIsDone']){
        if(window['rnmDeviceAddStepIIsDone'][i]){
            res.push(cast2number(i))
        }
    }
    return res
}
// get RnmDeviceStatusMappingHome list
export async function getRnmDeviceStatusMappingHomeList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDeviceStatusMappingHome> = await postBasic('/gusadminapi/v1/statusmappinghome/list',param,condition)
    return res
}
// get RnmDeviceStatusMappingOperation list
export async function getRnmDeviceStatusMappingOperationList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDeviceStatusMappingOperation> = await postBasic('/gusadminapi/v1/statusmappingoperation/list',param,condition)
    return res
}
// get RnmDeviceHeatbeatTemplate list
export async function getRnmDeviceHeatbeatTemplateList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDeviceHeatbeatTemplate> = await postBasic('/gusadminapi/v1/deviceheartbeattemplate/list',param,condition)
    return res
}
// get RnmDeviceHeatbeatTemplate
// get RnmDeviceHeatbeatTemplate list from local
export async function getRnmDeviceHeatbeatTemplateFromLocalPageVO(page,pageSize,condition){
    const list = await getRnmDeviceHeatbeatTemplateFromLocal()
    // log method and list
    console.log('getRnmDeviceHeatbeatTemplateListFromLocal',list)
    return {
        list,
        total:list.length,
        currentPage:page,
        status:0,
    }
}
// getRnmDeviceHeatbeatTemplateFromLocal
export async function getRnmDeviceHeatbeatTemplateFromLocal():Promise<RnmDeviceHeatbeatTemplate[]>{
    // get from window object for now
    const jsonPl = window['rnmDeviceHeatbeatTemplateLocal']
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    const res =  JSON.parse(jsonPl)
    return res
}
// get RnmDeviceHeatbeatExtractRule list
export async function getRnmDeviceHeartbeatExtractRuleList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDeviceHeartbeatExtractRule> = await postBasic('/gusadminapi/v1/deviceheartbeatextractrule/list',param,condition)
    return res
}
// get RnmDeviceHeatbeatExtractRule list from local
export async function getRnmDeviceHeartbeatExtractRuleFromLocalPageVO(page,pageSize,pl){
    // log method and pl
    console.log('getRnmDeviceHeartbeatExtractRuleFromLocalPageVO',pl)
    const list = await getRnmDeviceHeartbeatExtractRuleFromLocal(pl)
    // log method and list
    console.log('getRnmDeviceHeartbeatExtractRuleListFromLocal',list)
    return {
        list,
        total:list.length,
        currentPage:page,
        status:0,
    }
}
// getRnmDeviceHeartbeatExtractRuleFromLocal
export async function getRnmDeviceHeartbeatExtractRuleFromLocal(pl:{rdht_id:any}):Promise<RnmDeviceHeartbeatExtractRule[]>{
    const { rdht_id } = pl
    // get from window object for now
    if (!window['rnmDeviceHeartbeatExtractRuleLocal']) window['rnmDeviceHeartbeatExtractRuleLocal'] = {}
    const jsonPl = window['rnmDeviceHeartbeatExtractRuleLocal'][rdht_id]
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    const res =  JSON.parse(jsonPl)
    return res
}
// get RnmDeviceHeatbeatTransformRule list
export async function getRnmDeviceHeartbeatTransformRuleList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDeviceHeartbeatTransformRule> = await postBasic('/gusadminapi/v1/deviceheartbeattransformrule/list',param,condition)
    return res
}
// get RnmDeviceHeatbeatTransformRule list from local
export async function getRnmDeviceHeartbeatTransformRuleFromLocalPageVO(page,pageSize,pl){
    // log method and pl
    const list = await getRnmDeviceHeartbeatTransformRuleFromLocal(pl)
    return {
        list,
        total:list.length,
        currentPage:page,
        status:0,
    }
}

// getRnmDeviceHeartbeatTransformRuleFromLocal
export async function getRnmDeviceHeartbeatTransformRuleFromLocal(pl:{rdht_id:any}):Promise<RnmDeviceHeartbeatTransformRule[]>{
    const { rdht_id } = pl
    // get from window object for now
    if (!window['rnmDeviceHeartbeatTransformRuleLocal']) window['rnmDeviceHeartbeatTransformRuleLocal'] = {}
    const jsonPl = window['rnmDeviceHeartbeatTransformRuleLocal'][rdht_id]
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    const res =  JSON.parse(jsonPl)
    return res
}

// get RnmDevicePayloadTransformRule list
export async function getRnmDevicePayloadTransformRuleList(page,pageSize,condition){
    const param = {
        page,
        pageSize
    }
    const res:PagedVO<RnmDevicePayloadTransformRule> = await postBasic('/gusadminapi/v1/payloadtemplatetransformrule/list',param,condition)
    return res
}
// get RnmDevicePayloadTransformRule list from local
export async function getRnmDevicePayloadTransformRuleFromLocalPageVO(page,pageSize,pl){
    // log method and pl
    const list = await getRnmDevicePayloadTransformRuleFromLocal(pl)
    // log method and list
    console.log('getRnmDevicePayloadTransformRuleListFromLocal',list)
    return {
        list,
        total:list.length,
        currentPage:page,
        status:0,
    }
}
// getRnmDevicePayloadTransformRuleFromLocal
export async function getRnmDevicePayloadTransformRuleFromLocal(pl:{rdpt_id:any}):Promise<RnmDevicePayloadTransformRule[]>{
    const { rdpt_id } = pl
    // get from window object for now
    if (!window['rnmDevicePayloadTransformRuleLocal']) window['rnmDevicePayloadTransformRuleLocal'] = {}
    const jsonPl = window['rnmDevicePayloadTransformRuleLocal'][rdpt_id]
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    const res =  JSON.parse(jsonPl)
    return res
}
// add JfsdCategory
export async function addJfsdCategory(pl:JfsdCategory){
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-category/add',{},processSort(pl))
    return res
}
// addJfsdBannerDataForLogo
export async function addJfsdBannerDataForLogo(pl:JfsdBannerData){
    return await addJfsdBannerData(pl,1)
}
// addJfsdBannerDataFor3
export async function addJfsdBannerDataFor3(pl:JfsdBannerData){
    return await addJfsdBannerData(pl,3)
}
// add JfsdBannerDataFor4
export async function addJfsdBannerDataFor4(pl:JfsdBannerData){
    return await addJfsdBannerData(pl,4)
}
// add JfsdBannerData
export async function addJfsdBannerData(pl:JfsdBannerData,bid=2){
    const preparedPl = {
        ...pl,
        bid
    }
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-banner-data/add',{},processSort(preparedPl))
    return res
}
// process sort
function processSort(pl:any & {sort}){
    let { sort, ...rest } = pl
    sort = parseInt(''+sort)
    if (sort == 0) {
        sort = 'zero' as any
    }
    return {
        sort,
        ...rest
    }
}
// process pl:JfsdNews
function processJfsdNews(pl:JfsdNews & {meta_title, meta_keywords, meta_description}){
    // for [meta_title, meta_keywords, meta_description], extract value from pl, default empty string
    // then merge them into pl.extends, use JSON.stringify, then delete those keys in pl
    let { meta_title='', meta_keywords='', meta_description='', yuefen, ...rest } = processSort(pl)

    const mergedPl = {
        ...rest,
        extends:JSON.stringify({
            meta_title,
            meta_keywords,
            meta_description,
            yuefen
        })
    }
    return mergedPl
}
// add JfsdNews
export async function addJfsdNews(pl:JfsdNews){
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-news/add',{},processJfsdNews(pl as any))
    return res
}
// add JfsdNews for type = News
export async function addJfsdNewsForNews(pl:JfsdNews){
    const mergedPl = {
        ...pl,
        type:'News',
        catid:10,
        content:'emptystring',
        commodity:'emptystring'
    }
    return await addJfsdNews(mergedPl)
}
// add JfsdNews for type = News3
export async function addJfsdNewsForNews3(pl:JfsdNews){
    const mergedPl = {
        ...pl,
        type:'News3',
        commodity:'emptystring',
        catid:37,
    }
    return await addJfsdNews(mergedPl)
}
// add AllDataAdd
export async function addAllDataAdd(pl:AllDataAdd){
    const res:MessageVO = await postBasic('/gusadminapi/v1/alldata/add',{},pl)
    return res
}
// add RnmDeviceModel
export async function addRnmDeviceModel(pl:RnmDeviceModel){
    const res:MessageVO = await postBasic('/gusadminapi/v1/model/add',{},pl)
    return res
}
// addRnmDeviceModelToLocal
export async function addRnmDeviceModelToLocal(pl:RnmDeviceModel){
    const id = objectId(pl)
    pl.rdm_id = id as unknown as number
    // json stringify pl
    const jsonPl = JSON.stringify(pl)
    // store to window object for now
    window['rnmDeviceModelLocal'] = jsonPl
    // log save success
    console.log('rnmDeviceModelLocal saved',window['rnmDeviceModelLocal'])
}
// getRnmDeviceModelFromLocal
export async function getRnmDeviceModelFromLocal(){
    // get from window object for now
    const jsonPl = window['rnmDeviceModelLocal']
    // check undefined
    if(jsonPl == undefined){
        return undefined
    }
    console.log('rnmDeviceModelLocal get',jsonPl)
    const res =  JSON.parse(jsonPl)
    console.log('rnmDeviceModelLocal get res',res)
    return res
}
// getRnmDeviceModelById
export async function getRnmDeviceModelById(id:any){
    const res:SimpleObjVO<RnmDeviceModel> = await postBasic('/gusadminapi/v1/model/get',{id:toInt(id)},{})
    return res
}
// addRnmDeviceMode
export async function addRnmDeviceMode(pl:RnmDeviceMode){
    const res:MessageVO = await postBasic('/gusadminapi/v1/devicemode/add',{},pl)
    return res
}

// addRnmDevicePosters
export async function addRnmDevicePosters(pl:RnmDevicePosters){
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceposters/add',{},pl)
    return res
}
// update RnmDeviceMode
export async function updateRnmDeviceMode(pl:RnmDeviceMode){
    const { rdmd_id:id, ...rest } = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/devicemode/update',{id},rest)
    return res
}

// update RnmDevicePosters
export async function updateRnmDevicePosters(pl:RnmDevicePosters){
    const { rdp_id:id, ...rest } = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceposters/update',{id},rest)
    return res
}
// addRnmDeviceModeToLocal
export async function addRnmDeviceModeToLocal(pl:RnmDeviceMode){
    const id = objectId(pl)
    pl.rdmd_id = id as unknown as number
    // get current local
    const local = await getRnmDeviceModeFromLocal()
    // merge pl into local
    local.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(local)
    // store to window object for now
    window['rnmDeviceModeLocal'] = jsonPl
}
// add RnmDevicePosters to local
export async function addRnmDevicePostersToLocal(pl:RnmDevicePosters){
    // get current local
    const local = await getRnmDevicePostersFromLocal()
    // merge pl into local
    local.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(local)
    // store to window object for now
    window['rnmDevicePostersLocal'] = jsonPl
}
// getRnmDeviceModeFromLocal
export async function getRnmDeviceModeFromLocal() : Promise<RnmDeviceMode[]> {
    // get from window object for now
    const jsonPl = window['rnmDeviceModeLocal']
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    const parsed = JSON.parse(jsonPl) as RnmDeviceMode[]
    return parsed
}
// add RnmDevicePayloadTemplate
export async function addRnmDevicePayloadTemplate(pl:RnmDevicePayloadTemplate){
    const res:MessageVO = await postBasic('/gusadminapi/v1/payloadtemplate/add',{},pl)
    return res
}
// addRnmDevicePayloadTemplateToLocal
export async function addRnmDevicePayloadTemplateToLocal(pl:RnmDevicePayloadTemplate){
    const id = objectId(pl)
    pl.rdpt_id = id as unknown as number
    const list = await getRnmDevicePayloadTemplateFromLocal(pl.rdmd_id)
    // merge pl into list
    list.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(list)
    // store to window object for now
    window['rnmDevicePayloadTemplateLocal'][pl.rdmd_id] = jsonPl
}
// getRnmDevicePayloadTemplateFromLocal
export async function getRnmDevicePayloadTemplateFromLocal(rdmd_id:number):Promise<RnmDevicePayloadTemplate[]>{
    // get from window object for now
    if (!window['rnmDevicePayloadTemplateLocal']) window['rnmDevicePayloadTemplateLocal'] = {}
    const jsonPl = window['rnmDevicePayloadTemplateLocal'][rdmd_id]
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    return JSON.parse(jsonPl) as RnmDevicePayloadTemplate[]
}
// add RnmDeviceStatusMappingHome
export async function addRnmDeviceStatusMappingHome(pl:RnmDeviceStatusMappingHome){
    const res:MessageVO = await postBasic('/gusadminapi/v1/statusmappinghome/add',{},pl)
    return res
}
// addRnmDeviceStatusMappingHomeToLocal
export async function addRnmDeviceStatusMappingHomeToLocal(pl:RnmDeviceStatusMappingHome){
    const list = await getRnmDeviceStatusMappingHomeFromLocal()
    // merge pl into list
    list.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(list)
    // store to window object for now
    window['rnmDeviceStatusMappingHomeLocal'] = jsonPl
}
// getRnmDeviceStatusMappingHomeFromLocal
export async function getRnmDeviceStatusMappingHomeFromLocal():Promise<RnmDeviceStatusMappingHome[]>{
    // get from window object for now
    const jsonPl = window['rnmDeviceStatusMappingHomeLocal']
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    return JSON.parse(jsonPl) as RnmDeviceStatusMappingHome[]
}
// wrap getRnmDeviceStatusMappingHomeFromLocal to return PageVO<RnmDeviceStatusMappingHome>
export async function getRnmDeviceStatusMappingHomeFromLocalPageVO(page,pageSize){
    const list = await getRnmDeviceStatusMappingHomeFromLocal()
    return {
        list,
        total:list.length,
        currentPage:page,
        status:0
    }
}
// add RnmDeviceStatusMappingOperation
export async function addRnmDeviceStatusMappingOperation(pl:RnmDeviceStatusMappingOperation){
    const res:MessageVO = await postBasic('/gusadminapi/v1/statusmappingoperation/add',{},pl)
    return res
}
// addRnmDeviceStatusMappingOperationToLocal
export async function addRnmDeviceStatusMappingOperationToLocal(pl:RnmDeviceStatusMappingOperation){
    const list = await getRnmDeviceStatusMappingOperationFromLocal()
    // merge pl into list
    list.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(list)
    // store to window object for now
    window['rnmDeviceStatusMappingOperationLocal'] = jsonPl
}
// getRnmDeviceStatusMappingOperationFromLocal
export async function getRnmDeviceStatusMappingOperationFromLocal():Promise<RnmDeviceStatusMappingOperation[]>{
    // get from window object for now
    const jsonPl = window['rnmDeviceStatusMappingOperationLocal']
    // check undefined
    if(jsonPl == undefined){
        return []
    }
    return JSON.parse(jsonPl) as RnmDeviceStatusMappingOperation[]
}

// wrap getRnmDeviceStatusMappingOperationFromLocal to return PageVO<RnmDeviceStatusMappingOperation>
export async function getRnmDeviceStatusMappingOperationFromLocalPageVO(page,pageSize){
    const list = await getRnmDeviceStatusMappingOperationFromLocal()
    // log method and list
    console.log('getRnmDeviceStatusMappingOperationFromLocalPageVO',list)
    return {
        list,
        total:list.length,
        currentPage:page,
        status:0
    }
}
// add RnmDeviceHeatbeatTemplate
export async function addRnmDeviceHeatbeatTemplate(pl:RnmDeviceHeatbeatTemplate){
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeattemplate/add',{},pl)
    return res
}
// addRnmDeviceHeatbeatTemplateToLocal
export async function addRnmDeviceHeatbeatTemplateToLocal(pl:RnmDeviceHeatbeatTemplate){
    const id = objectId(pl)
    pl.rdht_id = id as unknown as number
    const list = await getRnmDeviceHeatbeatTemplateFromLocal()
    // merge pl into list
    list.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(list)
    // store to window object for now
    window['rnmDeviceHeatbeatTemplateLocal'] = jsonPl
}

// add RnmDeviceHeatbeatExtractRule
export async function addRnmDeviceHeartbeatExtractRule(pl:RnmDeviceHeartbeatExtractRule){
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeatextractrule/add',{},pl)
    return res
}

// addRnmDeviceHeatbeatExtractRuleToLocal
export async function addRnmDeviceHeartbeatExtractRuleToLocal(pl:RnmDeviceHeartbeatExtractRule){
    const list = await getRnmDeviceHeartbeatExtractRuleFromLocal(pl)
    // merge pl into list
    list.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(list)
    // store to window object for now
    window['rnmDeviceHeartbeatExtractRuleLocal'][pl.rdht_id] = jsonPl
}
// add RnmDeviceHeatbeatTransformRule
export async function addRnmDeviceHeartbeatTransformRule(pl:RnmDeviceHeartbeatTransformRule){
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeattransformrule/add',{},pl)
    return res
}
// addRnmDeviceHeatbeatTransformRuleToLocal
export async function addRnmDeviceHeartbeatTransformRuleToLocal(pl:RnmDeviceHeartbeatTransformRule){
    const list = await getRnmDeviceHeartbeatTransformRuleFromLocal(pl)
    // merge pl into list
    list.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(list)
    // store to window object for now
    window['rnmDeviceHeartbeatTransformRuleLocal'][pl.rdht_id] = jsonPl
}
// add RnmDevicePayloadTransformRule
export async function addRnmDevicePayloadTransformRule(pl:RnmDevicePayloadTransformRule){
    const res:MessageVO = await postBasic('/gusadminapi/v1/payloadtemplatetransformrule/add',{},pl)
    return res
}
// addRnmDevicePayloadTransformRuleToLocal
export async function addRnmDevicePayloadTransformRuleToLocal(pl:RnmDevicePayloadTransformRule){
    const list = await getRnmDevicePayloadTransformRuleFromLocal(pl)
    // merge pl into list
    list.push(pl)
    // json stringify pl
    const jsonPl = JSON.stringify(list)
    // store to window object for now
    window['rnmDevicePayloadTransformRuleLocal'][pl.rdpt_id] = jsonPl
}
// update JfsdCategory
export async function updateJfsdCategory(pl:JfsdCategory){
    const {id,...rest} = pl
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-category/update',{id},processSort(rest))
    return res
}
// update RnmDeviceModel
export async function updateRnmDeviceModel(pl:RnmDeviceModel){
    const {rdm_id:id,...rest} = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/model/update',{id},rest)
    return res
}
// update RnmDevicePayloadTemplate
export async function updateRnmDevicePayloadTemplate(pl:RnmDevicePayloadTemplate){
    const {rdpt_id:id,...rest} = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/payloadtemplate/update',{id},rest)
    return res
}
// update RnmDeviceStatusMappingHome
export async function updateRnmDeviceStatusMappingHome(pl:RnmDeviceStatusMappingHome){
    const {rdsmh_id:id,...rest} = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/statusmappinghome/update',{id},rest)
    return res
}
// update RnmDeviceStatusMappingOperation
export async function updateRnmDeviceStatusMappingOperation(pl:RnmDeviceStatusMappingOperation){
    const {rdsmo_id:id,...rest} = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/statusmappingoperation/update',{id},rest)
    return res
}
// update RnmDeviceHeatbeatTemplate
export async function updateRnmDeviceHeatbeatTemplate(pl:RnmDeviceHeatbeatTemplate){
    const {rdht_id:id,...rest} = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeattemplate/update',{id},rest)
    return res
}
// update RnmDeviceHeatbeatExtractRule
export async function updateRnmDeviceHeartbeatExtractRule(pl:RnmDeviceHeartbeatExtractRule){
    const {rdher_id:id,...rest} = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeatextractrule/update',{id},rest)
    return res
}

// update RnmDeviceHeartbeatTransformRule
export async function updateRnmDeviceHeartbeatTransformRule(pl:RnmDeviceHeartbeatTransformRule){
    const {rdhtr_id:id,...rest} = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeattransformrule/update',{id},rest)
    return res
}
// update RnmDevicePayloadTransformRule
export async function updateRnmDevicePayloadTransformRule(pl:RnmDevicePayloadTransformRule){
    const {rdptr_id:id,...rest} = pl
    const res:MessageVO = await postBasic('/gusadminapi/v1/payloadtemplatetransformrule/update',{id},rest)
    return res
}
// update JfsdBannerData
export async function updateJfsdBannerData(pl:JfsdBannerData){
    const {id,...rest} = pl
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-banner-data/update',{id},processSort(rest))
    return res
}
// update JfsdNews
export async function updateJfsdNews(pl:Partial<JfsdNews> & Pick<JfsdNews,'id'>){
    const {id,...rest} = pl
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-news/update',{id},processJfsdNews(rest as any))
    return res
}

// update JfsdNews for type = News
export async function updateJfsdNewsForNews(pl:Partial<JfsdNews> & Pick<JfsdNews,'id'>){
    const mergedPl = {
        ...pl,
        type:'News',
        catid:10
    }
    return await updateJfsdNews(mergedPl)
}
// update JfsdNews for type = News3
export async function updateJfsdNewsForNews3(pl:Partial<JfsdNews> & Pick<JfsdNews,'id'>){
    const mergedPl = {
        ...pl,
        type:'News3',
        catid:37
    }
    return await updateJfsdNews(mergedPl)
}
// update JfsdPage
export async function updateJfsdPage(pl:Partial<JfsdPage> & Pick<JfsdPage,'catid'>){
    const {catid,...rest} = pl
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-pages/update',{catid},rest)
    return res
}
// delete JfsdCategory
export async function deleteJfsdCategory(id:number){
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-category/delete',{id},{})
    return res
}
// delete JfsdNews
export async function deleteJfsdNews(id:number){
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-news/delete',{id},{})
    return res
}
// delete RnmDeviceModel
export async function deleteRnmDeviceModel(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/model/delete',{id},{})
    return res
}
// delete RnmDeviceMode
export async function deleteRnmDeviceMode(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/devicemode/delete',{id},{})
    return res
}
// delete RnmDeviceModeGivenRecord
export async function deleteRnmDeviceModeGivenRecord(record:RnmDeviceMode){
    return await deleteRnmDeviceMode(record.rdmd_id)
}

// delete RnmDevicePostersGivenRecord
export async function deleteRnmDevicePostersGivenRecord(record:RnmDevicePosters){
    return await deleteRnmDevicePosters(record.rdm_id)
}

// deleteRnmDevicePostersdeleteRnmDevicePosters
export async function deleteRnmDevicePosters(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceposters/delete',{id},{})
    return res
}
// delete RnmDeviceModeLocal
export async function deleteRnmDeviceModeLocal(record:RnmDeviceMode){
    const res =  await getRnmDeviceModeFromLocal()
    const newRes = res.filter(item=>item.mode_no != record.mode_no)
    window['rnmDeviceModeLocal'] = JSON.stringify(newRes)
}
// delete RnmDevicePosters
export async function deleteRnmDevicePostersLocal(record:RnmDevicePosters){
    const res =  await getRnmDevicePostersFromLocal()
    const newRes = res.filter(item=>item.rdm_id != record.rdm_id && item.poster_url != record.poster_url)
    window['rnmDevicePostersLocal'] = JSON.stringify(newRes)

}
// delete RnmDevicePayloadTemplate
export async function deleteRnmDevicePayloadTemplate(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/payloadtemplate/delete',{id},{})
    return res
}
// delete RnmDevicePayloadTemplateGivenRecord
export async function deleteRnmDevicePayloadTemplateGivenRecord(record:RnmDevicePayloadTemplate){
    return await deleteRnmDevicePayloadTemplate(record.rdpt_id)
}
// delete RnmDevicePayloadTemplateLocal
export async function deleteRnmDevicePayloadTemplateLocal(record:RnmDevicePayloadTemplate){
    const res =  await getRnmDevicePayloadTemplateFromLocal(record.rdmd_id)
    const newRes = {}
    window['rnmDevicePayloadTemplateLocal'] = JSON.stringify(newRes)
}
// delete RnmDeviceStatusMappingHome
export async function deleteRnmDeviceStatusMappingHome(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/statusmappinghome/delete',{id},{})
    return res
}
// deleteRnmDeviceStatusMappingHomeGivenRecord
export async function deleteRnmDeviceStatusMappingHomeGivenRecord(record:RnmDeviceStatusMappingHome){
    return await deleteRnmDeviceStatusMappingHome(record.rdsmh_id)
}
// delete RnmDeviceStatusMappingHomeLocal
export async function deleteRnmDeviceStatusMappingHomeLocal(record:RnmDeviceStatusMappingHome){
    // retrieve from window object, parse, then remove the one with id,then save back
    const jsonPl = window['rnmDeviceStatusMappingHomeLocal']
    // check undefined
    if(jsonPl == undefined){
        return undefined
    }
    const res =  JSON.parse(jsonPl)
    const newRes = res.filter(item=>item.dtype != record.dtype || item.dstatus != record.dstatus || item.home_list_render_state != record.home_list_render_state)
    window['rnmDeviceStatusMappingHomeLocal'] = JSON.stringify(newRes)
}
// delete RnmDeviceStatusMappingOperation
export async function deleteRnmDeviceStatusMappingOperation(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/statusmappingoperation/delete',{id},{})
    return res
}
// deleteRnmDeviceStatusMappingOperationGivenRecord
export async function deleteRnmDeviceStatusMappingOperationGivenRecord(record:RnmDeviceStatusMappingOperation){
    return await deleteRnmDeviceStatusMappingOperation(record.rdsmo_id)
}
// delete RnmDeviceStatusMappingOperationLocal
export async function deleteRnmDeviceStatusMappingOperationLocal(record:RnmDeviceStatusMappingOperation){
    // retrieve from window object, parse, then remove the one with id,then save back
    const jsonPl = window['rnmDeviceStatusMappingOperationLocal']
    // check undefined
    if(jsonPl == undefined){
        return undefined
    }
    const res =  JSON.parse(jsonPl)
    const newRes = res.filter(item=>item.dtype != record.dtype || item.dstatus != record.dstatus || item.operation_status != record.operation_status)
    window['rnmDeviceStatusMappingOperationLocal'] = JSON.stringify(newRes)
}
// delete RnmDeviceHeatbeatTemplate
export async function deleteRnmDeviceHeatbeatTemplate(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeattemplate/delete',{id},{})
    return res
}
// deleteRnmDeviceHeatbeatTemplateGivenRecord
export async function deleteRnmDeviceHeatbeatTemplateGivenRecord(record:RnmDeviceHeatbeatTemplate){
    return await deleteRnmDeviceHeatbeatTemplate(record.rdht_id)
}
// delete RnmDeviceHeatbeatTemplateLocal
export async function deleteRnmDeviceHeatbeatTemplateLocal(record:RnmDeviceHeatbeatTemplate){
    // retrieve from window object, parse, then remove the one with id,then save back
    const jsonPl = window['rnmDeviceHeatbeatTemplateLocal']
    // check undefined
    if(jsonPl == undefined){
        return undefined
    }
    const res =  JSON.parse(jsonPl)
    const newRes = res.filter(item=>item.dtype != record.dtype || item.match_reg != record.match_reg)
    window['rnmDeviceHeatbeatTemplateLocal'] = JSON.stringify(newRes)
}
// delete RnmDeviceHeatbeatExtractRule
export async function deleteRnmDeviceHeatbeatExtractRule(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeatextractrule/delete',{id},{})
    return res
}
// delete RnmDeviceHeatbeatExtractRuleGivenRecord
export async function deleteRnmDeviceHeatbeatExtractRuleGivenRecord(record:RnmDeviceHeartbeatExtractRule){
    return await deleteRnmDeviceHeatbeatExtractRule(record.rdher_id)
}
// delete RnmDeviceHeartbeatExtractRuleLocal
export async function deleteRnmDeviceHeartbeatExtractRuleLocal(record:RnmDeviceHeartbeatExtractRule){
    // retrieve from window object, parse, then remove the one with id,then save back
    const jsonPl = window['rnmDeviceHeartbeatExtractRuleLocal'][record.rdht_id]
    // check undefined
    if(jsonPl == undefined){
        return undefined
    }
    const res =  JSON.parse(jsonPl)
    const newRes = res.filter(item=>item.start_pos != record.start_pos || item.end_pos != record.end_pos || item.variable_name != record.variable_name)
    window['rnmDeviceHeartbeatExtractRuleLocal'][record.rdht_id] = JSON.stringify(newRes)
}
// delete RnmDeviceHeatbeatTransformRule
export async function deleteRnmDeviceHeartbeatTransformRule(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/deviceheartbeattransformrule/delete',{id},{})
    return res
}
// delete RnmDeviceHeartbeatTransformRuleGivenRecord
export async function deleteRnmDeviceHeartbeatTransformRuleGivenRecord(record:RnmDeviceHeartbeatTransformRule){
    return await deleteRnmDeviceHeartbeatTransformRule(record.rdhtr_id)
}
// delete RnmDeviceHeartbeatTransformRuleLocal
export async function deleteRnmDeviceHeartbeatTransformRuleLocal(record:RnmDeviceHeartbeatTransformRule){
    // retrieve from window object, parse, then remove the one with id,then save back
    const jsonPl = window['rnmDeviceHeartbeatTransformRuleLocal'][record.rdht_id]
    // check undefined
    if(jsonPl == undefined){
        return undefined
    }
    const res =  JSON.parse(jsonPl)
    const newRes = res.filter(item=>item.variable_name != record.variable_name || item.original_value != record.original_value || item.transformed_value != record.transformed_value)
    window['rnmDeviceHeartbeatTransformRuleLocal'][record.rdht_id] = JSON.stringify(newRes)
}
// delete RnmDevicePayloadTransformRule
export async function deleteRnmDevicePayloadTransformRule(id:number){
    const res:MessageVO = await postBasic('/gusadminapi/v1/payloadtemplatetransformrule/delete',{id},{})
    return res
}
// delete RnmDevicePayloadTransformRuleGivenRecord
export async function deleteRnmDevicePayloadTransformRuleGivenRecord(record:RnmDevicePayloadTransformRule){
    return await deleteRnmDevicePayloadTransformRule(record.rdpt_id)
}
// delete RnmDevicePayloadTransformRuleLocal
export async function deleteRnmDevicePayloadTransformRuleLocal(record:RnmDevicePayloadTransformRule){
    // retrieve from window object, parse, then remove the one with id,then save back
    const jsonPl = window['rnmDevicePayloadTransformRuleLocal'][record.rdpt_id]
    // check undefined
    if(jsonPl == undefined){
        return undefined
    }
    const res =  JSON.parse(jsonPl)
    const newRes = res.filter(item=>item.variable_name != record.variable_name || item.original_value != record.original_value || item.transformed_value != record.transformed_value)
    window['rnmDevicePayloadTransformRuleLocal'][record.rdpt_id] = JSON.stringify(newRes)
}
// delete JfsdBannerData
export async function deleteJfsdBannerData(id:number){
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-banner-data/delete',{id},{})
    return res
}
// get model max id
export async function getModelMaxId(){
    const res = await postBasic('/gusadminapi/v1/model/maxid',{},{})
    return res as SimpleObjVO<string>
}
// cache clear
export async function cacheClear(){
    const res:MessageVO = await postBasic('/i18napi/v1/sys/cache/clear',{},{})
    return res
}
// certbot renew
export async function certbotRenew(){
    const res:MessageVO = await postBasic('/i18napi/v1/sys/certbot/renew',{},{})
    return res
}
// httpd restart
export async function httpdRestart(){
    const res:MessageVO = await postBasic('/i18napi/v1/sys/httpd/restart',{},{})
    return res
}
// delete JfsdPage
export async function deleteJfsdPage(catid:number){
    const res:MessageVO = await postBasic('/i18napi/v1/jfsd-pages/delete',{catid},{})
    return res
}
// i18n upload image file
export const uploadImage = async (input: Blob | File | string, overrideUrl?:string): Promise<string> => {
    let blob: Blob|File;

    if (typeof input === 'string' && input.startsWith('blob:')) {
        // Input is a blob URL
        const blobResponse = await fetch(input);
        blob = await blobResponse.blob();
    } else if (input instanceof Blob || (input as any) instanceof File) {
        // Input is a Blob or File
        blob = input as Blob | File;
    } else {
        throw new Error('Unsupported input type for uploadImage');
    }
    const param = {}
    if(overrideUrl){
        param['ossUrl'] = overrideUrl
    }
    try {
        const data = await postFile('/i18napi/v1/image/upload', param, blob);
        return data.obj;
    } catch (error) {
        console.error("Error uploading image:", error);
        throw new Error('Failed to upload image');
    }
};

// i18n upload file
export const uploadFile = async (input: Blob | File | string): Promise<string> => {
    let blob: Blob|File;

    if (typeof input === 'string' && input.startsWith('blob:')) {
        // Input is a blob URL
        const blobResponse = await fetch(input);
        blob = await blobResponse.blob();
    } else if (input instanceof Blob || (input as any) instanceof File) {
        // Input is a Blob or File
        blob = input as Blob | File;
    } else {
        throw new Error('Unsupported input type for uploadFile');
    }
    const param = {}
    try {
        const data = await postFile('/i18napi/v1/file/upload', param, blob);
        return data.obj;
    } catch (error) {
        console.error("Error uploading file:", error);
        throw new Error('Failed to upload file');
    }
};
export async function batchAddRecordStore(pl:RecordStore[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/recordstore/batch/add',{},pl)
    return res
}
export async function batchAddRecordCompany(pl:RecordStore[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/recordstore/channel/batch/add',{},pl)
    return res
}

export async function channelBatchAddRecordStore(pl:RecordStore[]){
    const res:MessageVO = await postBasic('/clearingapi/v1/agreementChannel/batch/add',{},pl)
    return res
}
export async function channelBatchAddMapRelation(pl:RecordStore[]){

    const res:MessageVO = await postBasic('/clearingapi/v1/staffProvinceChannel/batch/add',{},pl)
    if(res.status==-1){
        notification['error']({
            message:res.message
        })
    }
    return res
}

export async function postAddRecordStore(batchNum:number){
    const res:MessageVO = await getBasicRaw('/clearingapi/v1/recordstore/post/upload',{batchNum})
    return res;
}
export async function postAddRecordCompany(batchNum:number){
    const res:MessageVO = await getBasicRaw('/clearingapi/v1/recordstore/channel/post/upload',{batchNum})
    return res;
}

export async function postAddChannelRecordStore(batchNum:number){
    const res:MessageVO = await getBasicRaw('/clearingapi/v1/agreementChannel/batch/add',{batchNum})
    return res;
}
export async function delRepeat(){
    const res:any= await getBasicRaw('/clearingapi/v1/agreementChannel/channel/deleteRepeat',{})
    return res;
}


export async function getrsCitys(province:string){
    const res:any= await getBasicRaw('/clearingapi/v1/agreementChannel/record/city',{province})
    return res
}
export async function getrsCountys(city:string){
    const province=''
    const res:any= await getBasicRaw('/clearingapi/v1/recordstore/channel/record/county',{province,city})
    return res
}

export async function getprovinces(){
    const res:any= await getBasicRaw('/clearingapi/v1/agreementChannel/record/province',{})
    return res
}
export async function getaddprovinces(){
    const res:any= await getBasicRaw('/clearingapi/v1/staffProvinceChannel/province',{})
    return res
}

export async function getdaqus(){
    const res:any= await getBasicRaw('/clearingapi/v1/channel/checkinrecord/checkin/regions',{})
    return res
}

export async function getshengqus(region:string){

    const res:any= await getBasicRaw('/clearingapi/v1/channel/checkinrecord/checkin/provinces',{region})
    return res
}
export async function getOtcdaqus(){
    const res:any= await getBasicRaw('/clearingapi/v1/checkinrecord/checkin/regions',{})
    return res
}
export async function getOtcshengqus(region:string){

    const res:any= await getBasicRaw('/clearingapi/v1/channel/checkinrecord/checkin/provinces',{region})
    return res
}
// export async function getglAreas(region:string){//斯利安
//      //name: '渠道组', value: '567389654'
//      //name: '院端组', value: '567311799'
//                              let did=''
//                              if(region=='渠道组'){
//                                  did='567389654'
//                              }else if(region=='院端组'){
//                                  did='567311799'
//                              }else{
//                                  did=''
//                              }
//     const res:any= await getBasicRaw('/clearingapi/v1/phototherapy/checkinrecord/checkin/areas',{groupCategoryId:did})
//     return res
// }

export async function getglAreasGl(region:string){//光疗 渠道组部门ID：567104691    院端组部门id：567287769

                            let did=''
                            if(region=='招商事业部'){//招商事业部 原渠道组
                                did='567104691'
                            }else if(region=='医院事业部'){//医院事业部 原院端组
                                did='567287769'
                            }else{
                                did=''
                            }
   const res:any= await getBasicRaw('/clearingapi/v1/phototherapy/checkinrecord/checkin/areas',{groupCategoryId:did})
   return res
}

// add RnmFaqQuestion
export async function addRnmFaqQuestion(pl: RnmFaqQuestion) {
    const res: MessageVO = await postBasic('/gusadminapi/v1/faqquestion/add', {}, pl);
    return res;
}

// update RnmFaqQuestion
export async function updateRnmFaqQuestion(pl: RnmFaqQuestion) {
    const { rfq_id: id, ...rest } = pl;
    const res: MessageVO = await postBasic('/gusadminapi/v1/faqquestion/update', { id }, rest);
    return res;
}

// get RnmFaqQuestion list from local
export async function getRnmFaqQuestionFromLocal() {
    // get from window object for now
    const jsonPl = window['rnmFaqQuestionLocal'];
    // check undefined
    if (jsonPl == undefined) {
        return [];
    }
    const res = JSON.parse(jsonPl);
    return res;
}

// get RnmFaqQuestion list from local with pagination
export async function getRnmFaqQuestionFromLocalPageVO(page: number, pageSize: number, condition: any) {
    const list = await getRnmFaqQuestionFromLocal();
    // log method and list
    console.log('getRnmFaqQuestionListFromLocal', list);
    return {
        list,
        total: list.length,
        currentPage: page,
        status: 0,
    };
}

// add RnmFaqQuestion to local
export async function addRnmFaqQuestionToLocal(pl: RnmFaqQuestion) {
    // get current local
    const local = await getRnmFaqQuestionFromLocal();
    // merge pl into local
    local.push(pl);
    // json stringify pl
    const jsonPl = JSON.stringify(local);
    // store to window object for now
    window['rnmFaqQuestionLocal'] = jsonPl;
}

// delete RnmFaqQuestion given record
export async function deleteRnmFaqQuestionGivenRecord(record: RnmFaqQuestion) {
    return await deleteRnmFaqQuestion(record.rfq_id);
}

// delete RnmFaqQuestion
export async function deleteRnmFaqQuestion(id: number) {
    const res: MessageVO = await postBasic('/gusadminapi/v1/faqquestion/delete', { id }, {});
    return res;
}

// delete RnmFaqQuestion from local
export async function deleteRnmFaqQuestionLocal(record: RnmFaqQuestion) {
    const res = await getRnmFaqQuestionFromLocal();
    const newRes = res.filter(item => item.rfq_id != record.rfq_id && item.question_text != record.question_text);
    window['rnmFaqQuestionLocal'] = JSON.stringify(newRes);
}

// get RnmFaqQuestion list
export async function getRnmFaqQuestionList(page: number, pageSize: number, condition: any) {
    const param = {
        page,
        pageSize
    };
    const res: PagedVO<RnmFaqQuestion> = await postBasic('/gusadminapi/v1/faqquestion/list', param, condition);
    return res;
}

// get RnmDeviceFaqAnswer list
export async function getRnmDeviceFaqAnswerList(page: number, pageSize: number, condition: any) {
    const param = {
        page,
        pageSize
    };
    const res: PagedVO<RnmDeviceFaqAnswer> = await postBasic('/gusadminapi/v1/faqanswer/list', param, condition);
    return res;
}

// get RnmDeviceFaqAnswer list from local
export async function getRnmDeviceFaqAnswerFromLocalPageVO(page: number, pageSize: number, pl: any) {
    const list = await getRnmDeviceFaqAnswerFromLocal(pl);
    console.log('getRnmDeviceFaqAnswerListFromLocal', list);
    return {
        list,
        total: list.length,
        currentPage: page,
        status: 0,
    };
}

// get RnmDeviceFaqAnswerFromLocal
export async function getRnmDeviceFaqAnswerFromLocal(pl: { rdfq_id: any }): Promise<RnmDeviceFaqAnswer[]> {
    const { rdfq_id } = pl;
    if (!window['rnmDeviceFaqAnswerLocal']) window['rnmDeviceFaqAnswerLocal'] = {};
    const jsonPl = window['rnmDeviceFaqAnswerLocal'][rdfq_id];
    if (jsonPl == undefined) {
        return [];
    }
    const res = JSON.parse(jsonPl);
    return res;
}

// add RnmDeviceFaqAnswer
export async function addRnmDeviceFaqAnswer(pl: RnmDeviceFaqAnswer) {
    const res: MessageVO = await postBasic('/gusadminapi/v1/faqanswer/add', {}, pl);
    return res;
}

// addRnmDeviceFaqAnswerToLocal
export async function addRnmDeviceFaqAnswerToLocal(pl: RnmDeviceFaqAnswer) {
    const list = await getRnmDeviceFaqAnswerFromLocal(pl);
    list.push(pl);
    const jsonPl = JSON.stringify(list);
    window['rnmDeviceFaqAnswerLocal'][pl.rdfq_id] = jsonPl;
}

// update RnmDeviceFaqAnswer
export async function updateRnmDeviceFaqAnswer(pl: RnmDeviceFaqAnswer) {
    const { rdfa_id: id, ...rest } = pl;
    const res: MessageVO = await postBasic('/gusadminapi/v1/faqanswer/update', { id }, rest);
    return res;
}

// delete RnmDeviceFaqAnswer
export async function deleteRnmDeviceFaqAnswer(id: number) {
    const res: MessageVO = await postBasic('/gusadminapi/v1/faqanswer/delete', { id }, {});
    return res;
}

// delete RnmDeviceFaqAnswerGivenRecord
export async function deleteRnmDeviceFaqAnswerGivenRecord(record: RnmDeviceFaqAnswer) {
    return await deleteRnmDeviceFaqAnswer(record.rdfa_id);
}

// delete RnmDeviceFaqAnswerLocal
export async function deleteRnmDeviceFaqAnswerLocal(record: RnmDeviceFaqAnswer) {
    const jsonPl = window['rnmDeviceFaqAnswerLocal'][record.rdfq_id];
    if (jsonPl == undefined) {
        return undefined;
    }
    const res = JSON.parse(jsonPl);
    const newRes = res.filter(item => item.rdfa_id != record.rdfa_id);
    window['rnmDeviceFaqAnswerLocal'][record.rdfq_id] = JSON.stringify(newRes);
}
export interface PageViewCountResponse {
    data: number;
}
// Function to count page views between a start and end date
export async function countPageViewsInRange(startDate: string, endDate: string): Promise<number> {
    // Prepare the query params
    const params = {
        start: startDate,
        end: endDate,
    };

    // Send GET request to the API endpoint
    const response: PageViewCountResponse = await getBasicRaw('/i18napi/v1/jfsd-pageview/count', params);

    // Return the count of page views
    return response.data;
}

// Function to count unique visitors by IP between a start and end date
export async function countUniqueVisitorsByIP(startDate: string, endDate: string): Promise<number> {
    // Prepare the query params
    const params = {
        start: startDate,
        end: endDate,
    };

    // Send GET request to the API endpoint for unique visitors by IP
    const response: PageViewCountResponse = await getBasicRaw('/i18napi/v1/jfsd-pageview/unique-visitors/ip', params);

    // Return the count of unique visitors
    return response.data;
}

// Function to count unique visitors by session between a start and end date
export async function countUniqueVisitorsBySession(startDate: string, endDate: string): Promise<number> {
    // Prepare the query params
    const params = {
        start: startDate,
        end: endDate,
    };

    // Send GET request to the API endpoint for unique visitors by session
    const response: PageViewCountResponse = await getBasicRaw('/i18napi/v1/jfsd-pageview/unique-visitors/session', params);

    // Return the count of unique visitors
    return response.data;
}

// add JfsdAppContent
export async function addJfsdAppContent(pl: JfsdAppContent) {
    const res: MessageVO = await postBasic('/i18napi/v1/jfsd-app-content/add', {}, pl);
    return res;
}

// get JfsdAppContent list with pagination
export async function getJfsdAppContentList(page: number, pageSize: number, condition: any) {
    const param = {
        page,
        pageSize
    };
    const res: PagedVO<JfsdAppContent> = await postBasic('/i18napi/v1/jfsd-app-content/list', param, condition);
    return res;
}

// get JfsdAppContent by id
export async function getJfsdAppContentById(id: number) {
    const res: SimpleObjVO<JfsdAppContent> = await getBasicRaw('/i18napi/v1/jfsd-app-content/getById', { id });
    return res;
}

// update JfsdAppContent
export async function updateJfsdAppContent(pl: JfsdAppContent) {
    const { id, ...rest } = pl;
    const res: MessageVO = await postBasic('/i18napi/v1/jfsd-app-content/update', { id }, rest);
    return res;
}

// delete JfsdAppContent
export async function deleteJfsdAppContent(id: number) {
    const res: MessageVO = await postBasic('/i18napi/v1/jfsd-app-content/delete', { id }, {});
    return res;
}

// delete JfsdAppContent given record
export async function deleteJfsdAppContentGivenRecord(record: JfsdAppContent) {
    return await deleteJfsdAppContent(record.id);
}
